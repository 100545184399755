import VideoBackgroundWithLogo from '@/features/LoginPage/components/VideoBackgroundWithLogo'

export const DesktopRecommendationPrompt = () => {
  return (
    <div className="flex h-dvh w-full flex-col gap-xl bg-container p-xl">
      <div className="flex h-[300px] items-center">
        <VideoBackgroundWithLogo />
      </div>
      <div className="flex flex-col items-center gap-m text-center">
        <header className="flex flex-col">
          <h1 className="text-size-primary font-medium leading-6 text-primary">
            You will love thesys on a big screen
          </h1>
        </header>
        <div className="flex flex-col">
          <p className="text-size-primary text-secondary">
            thesys is optimized for larger screens. For the best experience,
            please try accessing it from a desktop.
          </p>
        </div>
      </div>
    </div>
  )
}
