import authStore from '@/app/store/authStore'
import { redirectToLogin } from '@/lib/thesys/utils/common'
import { isAxiosError } from 'axios'
import { axiosInstance } from 'instrument'

export const setupAxios = (serverurl: string) => {
  axiosInstance.defaults.baseURL = serverurl

  // Add an interceptor to handle 401 responses and reload the page
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config

      if (isAxiosError(error) && error.response?.status === 401) {
        if (error.config?.url === '/auth/session/refresh') {
          redirectToLogin()
          return
        }
        if (!originalRequest._retry) {
          return await refreshAccessTokenAndRetry(originalRequest, error)
        } else {
          redirectToLogin()
        }
      }

      return Promise.reject(error)
    },
  )
}

async function refreshAccessTokenAndRetry(originalRequest: any, error: any) {
  originalRequest._retry = true

  try {
    await authStore.refetchAccessToken()
    return axiosInstance(originalRequest)
  } catch (refreshError) {
    redirectToLogin()
    return Promise.reject(error)
  }
}
