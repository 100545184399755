import clsx from '@/lib/utils/clsx'

import { forwardRef } from 'react'

export interface IconProps {
  size?: 'sm' | 'md'
  className?: string
  style?: React.CSSProperties
}

const Icon = forwardRef<HTMLSpanElement, React.PropsWithChildren<IconProps>>(
  ({ size, children, className, style }, ref) => {
    return (
      <span
        className={clsx(
          {
            'text-icon-sm': size === 'sm',
            'text-icon-md': size === 'md',
          },
          className,
          'inline-block',
        )}
        ref={ref}
        style={style}
      >
        {children}
      </span>
    )
  },
)

export default Icon
