import { useFeatureFlagEnabled } from 'posthog-js/react'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'

type Features =
  | 'UNDO_REDO'
  | 'UNSPLASH'
  | 'COMMENTS'
  | 'DELETE_KEY'
  | 'MULTIPLE_CARDS'
  | 'EXECUTE_FLOW_SERVER_SIDE'
  | 'DESKTOP_RECOMMENDATION_PROMPT'
  | 'PRE_ANALYTICS'
type FeatureFlags = {
  [key in Features]: boolean
}
const isLocalEnv = import.meta.env.MODE === 'development'

function getDefaultFeatureFlags(): FeatureFlags {
  return {
    UNDO_REDO: true,
    UNSPLASH: isLocalEnv,
    COMMENTS: isLocalEnv,
    DELETE_KEY: isLocalEnv,
    EXECUTE_FLOW_SERVER_SIDE: !isLocalEnv,
    MULTIPLE_CARDS: isLocalEnv,
    DESKTOP_RECOMMENDATION_PROMPT: !isLocalEnv,
    PRE_ANALYTICS: isLocalEnv,
  }
}

export const FeatureFlagContext = createContext<FeatureFlags>(
  getDefaultFeatureFlags(),
)

export const useFeatureFlags = () => useContext(FeatureFlagContext)

export const FeatureFlagProvider = ({ children }: PropsWithChildren) => {
  const isUndoRedoEnabled = useFeatureFlagEnabled('undo-redo')
  const isCommentsEnabled = useFeatureFlagEnabled('comments')
  const isDeleteKeyEnabled = useFeatureFlagEnabled('delete-key')
  const isUnsplashEnabled = useFeatureFlagEnabled('UNSPLASH')
  const isPreAnalyticsEnabled = useFeatureFlagEnabled('pre-analytics')
  const isMultipleCardsEnabled = useFeatureFlagEnabled('multiple-cards')

  const featureFlags = useMemo(() => {
    const defaultValues = getDefaultFeatureFlags()
    return {
      UNDO_REDO: isUndoRedoEnabled ?? defaultValues.UNDO_REDO,
      UNSPLASH: isUnsplashEnabled ?? defaultValues.UNSPLASH,
      COMMENTS: isCommentsEnabled ?? defaultValues.COMMENTS,
      DELETE_KEY: isDeleteKeyEnabled ?? defaultValues.DELETE_KEY,
      EXECUTE_FLOW_SERVER_SIDE: defaultValues.EXECUTE_FLOW_SERVER_SIDE,
      MULTIPLE_CARDS: isMultipleCardsEnabled ?? defaultValues.MULTIPLE_CARDS,
      DESKTOP_RECOMMENDATION_PROMPT:
        defaultValues.DESKTOP_RECOMMENDATION_PROMPT,
      PRE_ANALYTICS: isPreAnalyticsEnabled ?? defaultValues.PRE_ANALYTICS,
    }
  }, [
    isCommentsEnabled,
    isDeleteKeyEnabled,
    isUndoRedoEnabled,
    isUnsplashEnabled,
    isMultipleCardsEnabled,
    isPreAnalyticsEnabled,
  ])

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
