import * as coda from '@thesysdev/coda-client'
import axios from 'axios'

export const axiosInstance = axios.create({
  withCredentials: true,
})

export const api = coda.DefaultApiFactory(
  {
    isJsonMime: () => true,
  },
  undefined,
  axiosInstance,
)
