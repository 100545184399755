import Icon from '@/components/Icon'
import clsx from '@/lib/utils/clsx'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'

export interface ButtonProps {
  text?: React.ReactNode
  iconLeft?: React.FC<React.SVGProps<SVGSVGElement>>
  iconRight?: React.FC<React.SVGProps<SVGSVGElement>>
  textClassName?: string
  size?: 'accordion' | 'xs' | 'sm' | 'md' | 'lg'
  appearance?: 'primary' | 'secondary' | 'text'
  forceActive?: boolean
  className?: string
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const buttonVariants = cva(
  'inline-flex items-center text-size-primary rounded-md gap-xs justify-center overflow-hidden',
  {
    variants: {
      appearance: {
        primary:
          'bg-brand-el text-brand outline-inner outline-brand-el hover:bg-brand-el-hover hover:outline-brand-el shadow-sm ',
        secondary: [
          'bg-primary-el text-primary outline-inner outline-primary-el hover:bg-primary-el-hover hover:outline-primary-el shadow-sm',
          'focus:outline-container-hover focus:bg-sunk data-[force-active="true"]:outline-container-hover data-[force-active="true"]:bg-sunk', // active classes
        ],
        text: 'bg-transparent text-primary hover:outline-inner hover:outline-primary-el hover:bg-primary-el-hover focus:outline-inner focus:outline-container-hover focus:bg-sunk',
      },
      size: {
        accordion: 'px-m py-[1px]',
        xs: 'px-m py-3xs',
        sm: 'px-m py-2xs',
        md: 'px-m py-xs',
        lg: 'px-m py-s',
      },
      isIconOnly: {
        true: '',
        false: '',
      },
    },
    compoundVariants: [
      {
        isIconOnly: true,
        size: 'accordion',
        className: 'px-[1px] py-[1px] flex-shrink-0 flex-grow-0',
      },
      {
        isIconOnly: true,
        size: 'xs',
        className: 'px-3xs py-3xs flex-shrink-0 flex-grow-0',
      },
      {
        isIconOnly: true,
        size: 'sm',
        className: 'px-xs py-xs flex-shrink-0 flex-grow-0',
      },
      {
        isIconOnly: true,
        size: 'md',
        className: 'px-xs py-xs flex-shrink-0 flex-grow-0',
      },
      {
        isIconOnly: true,
        size: 'lg',
        className: 'px-s py-s flex-shrink-0 flex-grow-0',
      },
    ],
    defaultVariants: {
      size: 'md',
      appearance: 'primary',
      isIconOnly: false,
    },
  },
)

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      text,
      iconLeft: IconLeftSvg,
      iconRight: IconRightSvg,
      size,
      className,
      appearance,
      disabled,
      textClassName,
      forceActive,
      ...props
    },
    ref,
  ) => {
    const isIconOnly = !text

    return (
      <button
        ref={ref}
        data-force-active={forceActive}
        className={clsx(
          buttonVariants({
            size,
            appearance,
            isIconOnly,
          }),
          {
            'cursor-not-allowed opacity-50': disabled,
          },
          className,
        )}
        onClick={onClick}
        disabled={disabled}
        {...props}
      >
        {IconLeftSvg && (
          <Icon
            size={
              !isIconOnly || size === 'sm' || size === 'accordion' ? 'sm' : 'md'
            }
            className={clsx(
              { 'p-[0px]': size === 'accordion' },
              { 'p-[1px]': size === 'xs' },
              { 'p-[1px]': size === 'sm' },
              { 'p-3xs': size === 'md' },
              { 'p-3xs': size === 'lg' },
            )}
          >
            <IconLeftSvg />
          </Icon>
        )}
        {text && (
          <span
            className={clsx(
              'shrink overflow-hidden text-ellipsis whitespace-nowrap',
              textClassName,
            )}
          >
            {text}
          </span>
        )}
        {!isIconOnly && IconRightSvg && (
          <Icon size="md">
            <IconRightSvg />
          </Icon>
        )}
      </button>
    )
  },
)

export default Button
