import { datadogRum } from '@datadog/browser-rum'
import { reactPlugin } from '@datadog/browser-rum-react'

export const initTracing = () => {
  if (import.meta.env['VITE_DATADOG_ENABLED'] !== 'true') {
    return
  }
  initDatadogRum()
}

export const initDatadogRum = () => {
  datadogRum.init({
    allowedTracingUrls: [/https:\/\/.*\.thesys\.dev/],
    excludedActivityUrls: [/https:\/\/.*\.posthog\.com/],
    applicationId: import.meta.env['VITE_DATADOG_APP_ID'],
    clientToken: import.meta.env['VITE_DATADOG_CLIENT_TOKEN'],
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.com',
    service: 'thesys-app',
    env: import.meta.env.MODE,
    version: __APP_VERSION__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    // this is to prevent session recording for prototype views
    startSessionReplayRecordingManually: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    betaPlugins: [reactPlugin({ router: true })],
  })
}
