const bgColors = {
  pink: '#EC4899',
  violet: '#8B5CF6',
  ocean: '#3B82F6',
  sky: '#06B6D4',
  teal: '#14B8A6',
  emerald: '#10B981',
  lime: '#84CC16',
  ochre: '#EAB308',
  saffron: '#F59E0B',
}

const hashCode = (str: string): number => {
  let hash = 0,
    i,
    chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const bgColorForUserId = (userId: string): string => {
  const userHash = Math.abs(hashCode(userId))
  return Object.values(bgColors)[userHash % Object.keys(bgColors).length]!
}
