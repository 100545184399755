import { lazy } from '@/lib/utils/lazy'
export const AuthenticatedWaitlist = lazy(() =>
  import('./pages/AuthenticatedWaitlist').then((module) => ({
    default: module.AuthenticatedWaitlist,
  })),
)
export const UnauthenticatedWaitlist = lazy(() =>
  import('./pages/UnauthenticatedWaitlist').then((module) => ({
    default: module.UnauthenticatedWaitlist,
  })),
)
