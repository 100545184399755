import pic from '../assets/backgroundPhoto.webp'
import video from '../assets/backgroundVideo.mp4'
import LogoSVG from './LogoSVG'

const VideoBackgroundWithLogo = () => {
  return (
    <div className="flex h-full w-full justify-center overflow-hidden rounded-[24px]">
      <div className="relative h-full w-[500px] overflow-hidden rounded-[24px] border border-black/12">
        <video
          className="absolute left-[50%] top-[50%] h-[400px] w-[500px] -translate-x-1/2 -translate-y-1/2 object-cover object-center"
          autoPlay
          loop
          muted
          playsInline
          poster={pic}
        >
          <source
            src={video}
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          />
          Your browser does not support the video tag.
        </video>

        <div className="absolute left-[52.5%] top-[52%] -translate-x-1/2 -translate-y-1/2">
          <LogoSVG />
        </div>
      </div>
    </div>
  )
}

export default VideoBackgroundWithLogo
