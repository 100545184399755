import { setupAxios } from '@/app/AxiosSetup'
import GlobalError from '@/components/GlobalError'
import { ProtectedRoute } from '@/components/ProtectedRoute'
import { Toaster } from '@/components/sonner'
import { Dashboard } from '@/features/Dashboard'
import { LoginPage } from '@/features/LoginPage'
import { Profile } from '@/features/Profile'
import { Project } from '@/features/Project'
import { ProjectEditor } from '@/features/Project/ProjectEditor'
import { ProjectOnboarding } from '@/features/Project/ProjectOnboarding'
import { AuthenticatedPrototype } from '@/features/Project/Prototype/AuthenticatedPrototype'
import { PublicPrototype } from '@/features/Project/Prototype/PublicPrototype'
import {
  AuthenticatedWaitlist,
  UnauthenticatedWaitlist,
} from '@/features/Waitlist'
import { createBrowserRouter } from '@datadog/browser-rum-react/react-router-v6'
import { observer } from 'mobx-react-lite'
import { Navigate, Outlet, RouterProvider } from 'react-router-dom'
import './App.css'
import { LazyFramerFeatures } from './framer'
import { URL_PATHS } from './url-constants'

// setup axios
const serverurl = import.meta.env['VITE_CODA_URL']
setupAxios(serverurl)

const RootLayout = () => (
  <LazyFramerFeatures>
    <Toaster />
    <Outlet />
  </LazyFramerFeatures>
)

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    errorElement: <GlobalError />,
    children: [
      {
        path: URL_PATHS.login,
        element: <LoginPage />,
      },
      {
        path: URL_PATHS.unauthenticatedWaitlist,
        element: <UnauthenticatedWaitlist />,
      },
      {
        path: URL_PATHS.publicPrototypeProjectId,
        element: <PublicPrototype />,
      },
      {
        path: URL_PATHS.waitlist,
        element: <AuthenticatedWaitlist />,
      },
      {
        path: '/',
        element: <ProtectedRoute />,
        children: [
          {
            path: URL_PATHS.profile,
            element: <Profile />,
          },
          {
            path: '',
            element: <Dashboard />,
          },
          {
            path: URL_PATHS.projectCreate,
            element: <ProjectOnboarding />,
          },
          {
            path: URL_PATHS.projectWithProjectId,
            element: <Project />,
            children: [
              {
                path: URL_PATHS.editor,
                element: <ProjectEditor />,
              },
              {
                path: URL_PATHS.prototype,
                element: <AuthenticatedPrototype />,
              },
              {
                path: '',
                element: <Navigate to="editor" replace />,
              },
            ],
          },
          {
            path: '*',
            element: <div>404</div>,
          },
        ],
      },
    ],
  },
])

const App = observer(() => {
  return <RouterProvider router={router} />
})

export default App
