import { action, makeObservable, observable } from 'mobx'
import invariant from 'tiny-invariant'

export class ApiLinkedStore<T, R = void> {
  callback: (payload: R) => Promise<{ data: T }>
  state:
    | {
        apiCallStatus: 'initial' | 'loading'
      }
    | {
        apiCallStatus: 'success'
        data: T
      }
    | {
        apiCallStatus: 'error'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        error: any
      } = { apiCallStatus: 'initial' }
  private inFlightFetch: Promise<typeof this.state> | null = null

  constructor(callback: (payload: R) => Promise<{ data: T }>) {
    this.callback = callback
    makeObservable(this, {
      state: observable,
      setStoreState: action,
    })
  }

  private async _fetch(payload: R): Promise<typeof this.state> {
    try {
      this.setStoreState({
        apiCallStatus: 'success',
        data: (await this.callback(payload)).data,
      })
    } catch (e) {
      this.setStoreState({ apiCallStatus: 'error', error: e })
    }
    this.inFlightFetch = null
    return this.state
  }

  async fetch(payload: R) {
    if (this.inFlightFetch) {
      return this.inFlightFetch
    }
    this.setStoreState({ apiCallStatus: 'loading' })
    this.inFlightFetch = this._fetch(payload)
    return this.inFlightFetch
  }

  get isInitial() {
    return this.state.apiCallStatus === 'initial'
  }

  get isLoading() {
    return this.state.apiCallStatus === 'loading'
  }

  get isError() {
    return this.state.apiCallStatus === 'error'
  }

  setStoreState(newState: this['state']) {
    this.state = newState
  }

  assertFetchData() {
    invariant(this.state.apiCallStatus === 'success')
    return this.state.data
  }

  reset() {
    this.setStoreState({ apiCallStatus: 'initial' })
  }
}
