// eslint-disable-next-line no-restricted-imports
import { clsx, type ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

type TypographyClassGroup = 'typography'
type BoxShadowClassGroup = 'shadow'

const twMerge = extendTailwindMerge<TypographyClassGroup, BoxShadowClassGroup>({
  extend: {
    theme: {
      spacing: [
        '3xs',
        '2xs',
        'xs',
        's',
        'm',
        'l',
        'xl',
        '2xl',
        '3xl',
        't-comp-000',
        't-comp-3xs',
        't-comp-2xs',
        't-comp-xs',
        't-comp-s',
        't-comp-m',
        't-comp-l',
        't-comp-xl',
      ],
    },
    classGroups: {
      'outline-style': ['outline-inner'],
      rounded: [
        'rounded-t-comp-000',
        'rounded-t-comp-3xs',
        'rounded-t-comp-2xs',
        'rounded-t-comp-xs',
        'rounded-t-comp-s',
        'rounded-t-comp-m',
        'rounded-t-comp-l',
        'rounded-t-comp-xl',
        'rounded-t-comp-2xl',
        'rounded-t-comp-3xl',
        'rounded-t-comp-4xl',
        'rounded-t-comp-full',
        'rounded-t-comp-clickables',
      ],
      'font-size': [
        'text-xs',
        'text-sm',
        'text-lg',
        'text-size-primary',
        'text-size-title',
        'text-size-body',
        'text-size-body-link',
        'text-size-label',
        'text-size-label-sm',
        'text-size-label-xs',
        'text-icon-sm',
        'text-icon-md',
      ],
      'text-color': [
        'text-t-comp-brand',
        'text-t-comp-brand-secondary',
        'text-t-comp-primary',
        'text-t-comp-secondary',
        'text-t-comp-disabled',
        'text-t-comp-danger',
        'text-t-comp-success',
        'text-t-comp-link',
        'text-t-comp-info',
        'text-t-comp-user-bubble',
        'text-t-comp-response-bubble',
        'text-brand',
        'text-brand-secondary',
        'text-primary',
        'text-secondary',
        'text-disabled',
        'text-danger',
        'text-success',
        'text-info',
        'text-link',
        'text-highlight',
        'text-secondary-violet',
      ],
      'bg-color': [
        'bg-t-comp-background',
        'bg-t-comp-brand-el',
        'bg-t-comp-brand-el-hover',
        'bg-t-comp-container',
        'bg-t-comp-overlay',
        'bg-t-comp-sunk',
        'bg-t-comp-container-hover',
        'bg-t-comp-danger',
        'bg-t-comp-success',
        'bg-t-comp-user-bubble',
        'bg-t-comp-response-bubble',
        'bg-t-comp-chat-bg',
        'bg-fill',
        'bg-brand-el',
        'bg-brand-el-hover',
        'bg-container',
        'bg-overlay',
        'bg-sunk',
        'bg-elevated',
        'bg-primary-el',
        'bg-primary-el-hover',
        'bg-danger-container',
        'bg-success-container',
        'bg-brand-container',
        'bg-info-container',
        'bg-accent-container',
      ],
      'border-color': [
        'border-t-comp-default',
        'border-t-comp-interactive-el',
        'border-t-comp-interactive-el-hover',
        'border-t-comp-interactive-el-selected',
        'border-black',
        'border-default',
        'border-brand-el',
        'border-container',
        'border-container-hover',
        'border-danger-container',
        'border-primary-el',
        'border-separators',
        'border-sunk',
        'border-success-container',
        'border-selected',
        'border-accent-container',
      ],
      fill: [
        'fill-t-comp-background',
        'fill-t-comp-brand-el',
        'fill-t-comp-brand-el-hover',
        'fill-t-comp-container',
        'fill-t-comp-overlay',
        'fill-t-comp-sunk',
        'fill-t-comp-container-hover',
        'fill-t-comp-danger',
        'fill-t-comp-success',
        'fill-t-comp-default', // this is exception
      ],
      stroke: [
        'stroke-t-comp-default',
        'stroke-t-comp-interactive-el',
        'stroke-t-comp-interactive-el-hover',
        'stroke-t-comp-interactive-el-selected',
      ],
      'outline-color': [
        'outline-black',
        'outline-default',
        'outline-brand-el',
        'outline-container',
        'outline-container-hover',
        'outline-danger-container',
        'outline-primary-el',
        'outline-separators',
        'outline-sunk',
        'outline-success-container',
        'outline-selected',
        'outline-accent-container',
        'outline-t-comp-default',
        'outline-t-comp-interactive-el',
        'outline-t-comp-interactive-el-hover',
        'outline-t-comp-interactive-el-selected',
      ],
      typography: [
        't-comp-typography-primary',
        't-comp-typography-title',
        't-comp-typography-body',
        't-comp-typography-body-link',
        't-comp-typography-label',
        't-comp-typography-label-heavy',
        't-comp-typography-label-s',
        't-comp-typography-label-s-heavy',
        't-comp-typography-label-xs',
        't-comp-typography-label-xs-heavy',
      ],
      shadow: [
        'shadow-t-comp-small',
        'shadow-t-comp-medium',
        'shadow-t-comp-large',
        'shadow-t-comp-extra-large',
        'shadow-modal',
        'shadow-sm',
        'shadow-md',
        'shadow-lg',
        'shadow-xl',
        'shadow-2xl',
        'shadow-3xl',
        'shadow-none',
      ],
    },
  },
})

export default (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}
