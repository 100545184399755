import authStore from '@/app/store/authStore'
import { default as userInfoStore } from '@/app/store/userInfoStore'
import { useFeatureFlags } from '@/contexts/FeatureFlagContext'
import clsx from '@/lib/utils/clsx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { DesktopRecommendationPrompt } from '../DesktopRecommendationPrompt'

export const ProtectedRoute = observer(() => {
  const location = useLocation()
  const featureFlags = useFeatureFlags()
  const isDesktopRecommendationPromptEnabled =
    featureFlags.DESKTOP_RECOMMENDATION_PROMPT

  useEffect(() => {
    authStore.fetchAccessToken().then(() => {
      userInfoStore.fetchUserInfo()
    })
  }, [])

  const apiCallStatus = [
    authStore.accessToken.state.apiCallStatus,
    userInfoStore.userInfo.state.apiCallStatus,
  ]

  if (userInfoStore.userInfo.state.apiCallStatus === 'error') {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (apiCallStatus.some((status) => ['initial', 'loading'].includes(status))) {
    return null
  }

  return (
    <>
      {isDesktopRecommendationPromptEnabled && (
        <div className="lg:hidden">
          <DesktopRecommendationPrompt />
        </div>
      )}
      <div
        className={clsx(
          {
            'hidden lg:block': isDesktopRecommendationPromptEnabled,
          },
          {
            block: !isDesktopRecommendationPromptEnabled,
          },
        )}
      >
        <Outlet />
      </div>
    </>
  )
})
