import { LazyMotion } from 'framer-motion'

const features = () =>
  import('./features').then((module) => {
    return module.domMax
  })

export const LazyFramerFeatures = ({ children }: React.PropsWithChildren) => {
  return <LazyMotion features={features}>{children}</LazyMotion>
}
